import { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Typography, CTALink, BynderPicture } from 'common/widgets'
import classNames from './styles.module.scss'
import ImageType from '../types/ImageType'


Slide.propTypes = {
  image: ImageType,
  tag: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  caption: PropTypes.string,
  cta_link: PropTypes.string.isRequired,
  priority: PropTypes.bool,
}

Slide.defaultProps = {
  color: undefined,
  text: undefined,
  image: {},
  caption: '',
  priority: false,
}

export default function Slide({ image, tag, heading, text, caption, cta_link: ctaLink, priority }) {
  const newHeading = useMemo(() => {
    const newHeading = heading.split('\r\n')

    return newHeading.map((heading, index) => (
      <Typography
        key={index}
        variant="h2"
        tag="div"
        color="white"
        className={classNames.heading}
        style={{ transitionDelay: `${1 + 0.3 * index}s` }}
      >
        {heading}
      </Typography>
    ))
  }, [heading])

  return (
    <div className={classNames.slide}>
      <div className={classNames.slideImage}>
        <BynderPicture
          image={image}
          priority={priority}
          mobileImage={{
            maxWidth: 800,
            maxHeight: 800,
            sizes: '100vw',
          }}
          desktopImage={{
            maxWidth: 1920,
            maxHeight: 700,
            sizes: '100vw',
          }}
        />
      </div>
      <div className={classNames.slideInfo}>
        <div className={classNames.borderTop} />
        <div className={classNames.borderRight} />
        <div className={classNames.borderBottom} />
        <div className={classNames.borderLeft} />
        <div className={classNames.infoText}>
          <div className={classNames.inlineContainer}>
            <Typography variant="subhead" color="white" className={classNames.tag} preLine>{tag}</Typography>
          </div>
          <div className={cx(classNames.inlineContainer, classNames.headingContainer)}>
            {newHeading}
          </div>
          {text && (
            <div className={classNames.inlineContainer}>
              <Typography color="white" className={classNames.text}>{text}</Typography>
            </div>
          )}
        </div>
        <CTALink
          ctaURL={ctaLink}
          icon="chevron-right"
          cta={caption}
          className={classNames.cta}
        />
      </div>
    </div>
  )
}
