import get from 'lodash/get'
import PropTypes from 'prop-types'

import { Typography, BynderPicture } from 'common/widgets'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


ImageSlide.propTypes = {
  image: ImageType.isRequired,
  priority: PropTypes.bool.isRequired,
}


export default function ImageSlide({ image, priority }) {
  return (
    <div>
      <div className={classNames.imageWrapper}>
        <BynderPicture
          image={image}
          priority={priority}
          mobileImage={{
            maxWidth: 996,
            maxHeight: 830,
            aspectRatio: 6 / 5,
            sizes: '100vw',
          }}
          desktopImage={{
            maxWidth: 1080,
            maxHeight: 608,
            aspectRatio: 16 / 9,
            sizes: '(max-width: 1440px) 70vw, 960px',
          }}
        />
      </div>
      <Typography variant="small" className={classNames.caption}>{get(image, 'data.caption')}</Typography>
    </div>
  )
}
