import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import withScroll from 'common/utils/withScroll'
import { Container, SchoolFilters, Typography, GradientBlock, BynderPicture } from 'common/widgets'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


const propTypes = {
  data: PropTypes.shape({
    pretitle: PropTypes.string,
    pretitle_font_size: PropTypes.oneOf(['normal', 'large', 'small']),
    title: PropTypes.string,
    title_font_size: PropTypes.oneOf(['normal', 'large', 'small']),
    subtitle: PropTypes.string,
    subtitle_font_size: PropTypes.oneOf(['normal', 'large', 'small']),
    filter_caption: PropTypes.string,
    button_caption: PropTypes.string,
    filter_caption_font_size: PropTypes.string,
    filtersHeading: PropTypes.string,
    image: ImageType.isRequired,
  }),
  type: PropTypes.string,
}

const defaultProps = {
  data: {},
  type: undefined,
}

const HeroImage = forwardRef(({ data, type }, ref) => {
  return (
    <div ref={ref} className={classNames.wrapper}>
      <BynderPicture
        image={data.image}
        priority
        mobileImage={{
          maxWidth: 1000,
          maxHeight: 411,
          sizes: '100vw',
        }}
        desktopImage={{
          maxWidth: 1920,
          maxHeight: 658,
          sizes: '100vw',
        }}
      />
      <GradientBlock />
      <Container size="xl">
        <Typography variant="h1" color="white" className={classNames.headingContainer}>
          {data.pretitle && (
            <span className={cx(classNames.heading, classNames[data.pretitle_font_size])}>{data.pretitle + ' '}</span>
          )}
          {data.title && (
            <span className={cx(classNames.heading, classNames[data.title_font_size])}>{data.title + ' '}</span>
          )}
          {data.subtitle && (
            <span className={cx(classNames.heading, classNames[data.subtitle_font_size])}>{data.subtitle + ' '}</span>
          )}
        </Typography>
        {type === 'home_page_hero_image_with_quick_filter' && (
          <div className={classNames.filters}>
            <SchoolFilters
              heading={data.filter_caption}
              headingFontSize={data.filter_caption_font_size}
              btnCaption={data.button_caption}
            />
          </div>
        )}
      </Container>
    </div>
  )
})

HeroImage.propTypes = propTypes
HeroImage.defaultProps = defaultProps

export default withScroll(HeroImage, classNames.active)
