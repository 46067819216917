import { useEffect, useCallback, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Container, Typography } from 'common/widgets'
import Score from './widgets/Score'
import Video from './widgets/Video'
import classNames from './styles.module.scss'


CardWithVideo.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    review_score: PropTypes.number,
    subheading: PropTypes.string,
    text: PropTypes.string,
    video_url: PropTypes.string,
    video_poster_url: PropTypes.string,
    aspect_ratio: PropTypes.oneOf(['v', 'h']),
  }),
}

CardWithVideo.defaultProps = {
  data: {},
}

export default function CardWithVideo({ data }) {
  const [isVisible, setIsVisible] = useState(false)
  const container = useRef()
  const handleScroll = useCallback(() => {
    if(
      container.current.getBoundingClientRect().top
      < window.innerHeight / 2 && data?.review_score
    ) {
      setIsVisible(true)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setIsVisible])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={classNames.blockWrapper}>
      <Container size="xl">
        <div className={cx(classNames.row, data.aspect_ratio === 'v' ? classNames.vertical : classNames.horizontal)} ref={container}>
          {data.heading && (
            <Typography variant="h2" className={classNames.heading}>
              {data.heading}
            </Typography>
          )}
          {data.subheading && (
            <Typography
              variant="h4"
              tag="h3"
              color="light-black"
              className={classNames.subheading}
            >
              {data.subheading}
            </Typography>
          )}
          <Typography
            variant="content"
            color="light-black"
            className={classNames.text}
          >
            {data.text}
          </Typography>
          {data?.review_score && <Score isVisible={isVisible} score={data.review_score} />}
          <Video video={data.video_url} videoImage={data.video_poster_url} aspectRatio={data.aspect_ratio} withHeading={Boolean(data.heading)} />
        </div>
      </Container>
    </div>
  )
}
