import { Row, Col } from 'antd'
import { useState, useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import get from 'lodash/get'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import classNames from './styles.module.scss'
import { ImageType } from '../../../types'
import BynderPicture from 'common/widgets/BynderPicture/BynderPicture'
import bynderDATLoader from 'common/utils/bynderDATLoader'


const Lightbox = dynamic(() => import('./Lightbox'), { ssr: false })

ImageGridView.propTypes = {
  images: PropTypes.arrayOf(ImageType).isRequired,
}

export default function ImageGridView({ images }) {
  const { locale } = useRouter()
  const [isOpenIndex, handleOpen] = useState(null)
  const parsedImages = useMemo(() => images.map(i => ({
    bynder: i.bynder,
    data: {
      alttext: get(i, 'data.alttext', get(i, `data.alttext_${locale}`)),
      name: get(i, 'data.name', get(i, `data.name_${locale}`)),
    },
    caption: get(i, 'data.caption', get(i, `data.caption_${locale}`)),
  })), [images])

  const ligtboxImages = useMemo(() => parsedImages.map(image => ({
    src: bynderDATLoader({
      src: `https://img.boalingua.ch/transform/${image.bynder}/${image.data.name}`,
      width: 1920,
      maxWidth: 1920,
      maxHeight: 1080,
    }),
    caption: image.caption,
  })), [parsedImages])


  const firstImage = useMemo(() => get(parsedImages, '[0]', {}), [parsedImages])
  const imageCols = useMemo(() => (
    <Row className={classNames.imageRow}>
      <div
        className={classNames.leftImage}
        onClick={_ => handleOpen(0)}
      >
        <BynderPicture
          image={firstImage}
          mobileImage={{
            maxWidth: 400,
            maxHeight: 400,
            sizes: '54vw',
          }}
          desktopImage={{
            maxWidth: 820,
            maxHeight: 862,
            sizes: '(max-width: 1440px) 30vw, 410px',
          }}
          priority
        />
      </div>
      {parsedImages.length > 1 && (
        <div>
          <Row className={cx(classNames.rightRow, classNames.bigSize)}>
            <Col span={24} sm={16} lg={24} className={classNames.middleImage} onClick={_ => handleOpen(1)}>
              <BynderPicture
                image={parsedImages[1]}
                mobileImage={{
                  maxWidth: 300,
                  maxHeight: 150,
                  sizes: '46vw',
                }}
                desktopImage={{
                  maxWidth: 492,
                  maxHeight: 284,
                  sizes: '(max-width: 1024px) 36vw, (max-width: 1440px) 13vw, 246px',
                }}
                priority
              />
            </Col>
            <Col span={24} sm={8} lg={24} className={classNames.mobileCol}>
              <Row gutter={0} className={classNames.rightRow}>
                {parsedImages.slice(2).map((image, index) => (
                  <Col
                    span={24}
                    key={image.url}
                    sm={24}
                    lg={24}
                    onClick={_ => handleOpen(index + 2)}
                    className={cx(index > 1 && classNames.hidden, classNames.rightImage)}
                  >
                    <BynderPicture
                      image={image}
                      mobileImage={{
                        maxWidth: 300,
                        maxHeight: 150,
                        sizes: '46vw',
                      }}
                      desktopImage={{
                        maxWidth: 492,
                        maxHeight: 284,
                        sizes: '(max-width: 1024px) 18vw, (max-width: 1440px) 13vw, 246px',
                      }}
                      priority={index < 2}
                    />
                    {index === 0 && (
                      <div className={classNames.enlargePlaceholder} >
                        <FontAwesomeIcon icon="search-plus" />
                      </div>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </Row>
  ), [parsedImages])
  return (
    <Fragment>
      {imageCols}
      {Number.isInteger(isOpenIndex) && (
        <Lightbox
          mainSrc={ligtboxImages[isOpenIndex].src}
          nextSrc={ligtboxImages[(isOpenIndex + 1) % (ligtboxImages.length)].src}
          prevSrc={ligtboxImages[(isOpenIndex + ligtboxImages.length - 1) % ligtboxImages.length].src}
          onCloseRequest={() => handleOpen(null)}
          onMovePrevRequest={() => handleOpen((isOpenIndex + ligtboxImages.length - 1) % ligtboxImages.length)}
          onMoveNextRequest={() => handleOpen((isOpenIndex + 1) % ligtboxImages.length)}
          imageCaption={ligtboxImages[isOpenIndex].caption}
          imageTitle={<span className={classNames.counter}>{isOpenIndex + 1 + '/' + ligtboxImages.length}</span>}
          wrapperClassName={classNames.lightWrapper}
        />
      )}
    </Fragment>
  )
}
