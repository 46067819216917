import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import cx from 'classnames'

import { Container, CTALink, Typography, GradientBlock, BynderPicture } from 'common/widgets'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


HeroImageSimple.propTypes = {
  data: PropTypes.shape({
    image: ImageType,
    heading: PropTypes.string,
    text: PropTypes.string,
    cta_link_caption: PropTypes.string,
    cta_link: PropTypes.string,
    cta_link_type: PropTypes.string,
  }),
}

HeroImageSimple.defaultProps = {
  data: {},
}

export default function HeroImageSimple({ data }) {
  return (
    <div className={classNames.wrapper}>
      <BynderPicture
        image={data.image}
        priority
        desktopImage={{
          maxWidth: 1920,
          maxHeight: 500,
          sizes: '100vw',
        }}
        mobileImage={{
          maxWidth: 800,
          maxHeight: 500,
          sizes: '100vw',
        }}
      />
      <GradientBlock />
      <Container size="xl">
        <Row>
          <Col span={24} lg={12} xl={10}>
            <Typography variant="h2" color="white" preLine>{data.heading}</Typography>
            <Typography color="white" className={classNames.text}>{data.text}</Typography>
            <CTALink
              cta={data.cta_link_caption}
              ctaURL={data.cta_link}
              type={data.cta_link_type}
              className={cx(classNames.cta, data.cta_link_caption.length > 20 && classNames.longCaption)}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
