import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'

import { Container, CTALink, Typography, BlockWrapper, BynderPicture } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'
import { ImageType } from '../types'


ImageGrid.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: ImageType,
      heading: PropTypes.string,
      hover_text: PropTypes.string,
      cta_url: PropTypes.string,
      cta_link_type: PropTypes.string,
      cta_link_caption: PropTypes.string,
    })),
  }),
}

ImageGrid.defaultProps = {
  data: {},
}

export default function ImageGrid({ data }) {
  const { gettext } = useTranslations()
  const images = useMemo(() => data.items.map(({
    image,
    heading,
    hover_text: hoverText,
    cta_link: ctaLink,
    cta_link_caption: ctaCaption,
    cta_link_type: ctaType,
    image_size: imageSize,
  }, idx) => {
    const desktopImageProps = {
      maxWidth: 450,
      maxHeight: 300,
      sizes: '(max-width: 1440px) 28vw, 450px',
    }
    const desktopWideImageProps = {
      maxWidth: 910,
      maxHeight: 300,
      sizes: '(max-width: 1440px) 58vw, 910px',
    }
    return (
      <Col
        key={idx + heading}
        span={24}
        md={12}
        lg={imageSize === 'wide' ? 16 : 8}
        className={classNames.column}
      >
        <div className={classNames.imageContainer}>
          <div className={classNames.image}>
            <BynderPicture
              image={image}
              mobileImage={{
                maxWidth: 600,
                maxHeight: 500,
                sizes: '90vw',
              }}
              desktopImage={imageSize === 'wide' ? desktopWideImageProps : desktopImageProps}
            />
          </div>
          <div className={classNames.back}>
            <div>
              <Typography variant="h3" color="white">{heading}</Typography>
              <Typography varinat="content" color="white" className={classNames.text}>{hoverText}</Typography>
            </div>
            {ctaLink && (
              <CTALink
                cta={ctaCaption || gettext('Read more')}
                ctaURL={ctaLink}
                icon="chevron-right"
                type={ctaType}
              />
            )}
          </div>
        </div>
      </Col>
    )
  }), [data.items])

  return (
    <BlockWrapper>
      <Container size="xl">
        <Row>{images}</Row>
      </Container>
    </BlockWrapper>
  )
}
