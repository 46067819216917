import { useEffect, useRef } from 'react'


export default function withScroll(Component, className) {
  return function withScrollHOC(props) {
    const ref = useRef()

    useEffect(() => {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting && entry.target === ref?.current) {
            ref.current.classList.add(className)
            observer.disconnect()
          }
        })
      }, {
        root: document,
        rootMargin: '0px',
        threshold: 0.75,
      })
      if(ref.current) {
        observer.observe(ref.current)
      }

      return () => {
        observer.disconnect()
      }
    }, [])

    return <Component {...props} ref={ref} />
  }
}
