import PropTypes from 'prop-types'

import { Container, SchoolFilters, GradientBlock, BynderPicture } from 'common/widgets'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


HeroImageFilter.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    button_caption: PropTypes.string,
    heading_font_size: PropTypes.oneOf(['small', 'normal', 'large']),
    image: ImageType.isRequired,
  }),
}

HeroImageFilter.defaultProps = {
  data: {},
}

export default function HeroImageFilter({ data: { heading, heading_font_size: headingFontSize, button_caption: btnCaption, image }, ...rest }) {
  return (
    <div className={classNames.wrapper}>
      <BynderPicture
        image={image}
        priority
        desktopImage={{
          maxWidth: 1920,
          maxHeight: 500,
          sizes: '100vw',
        }}
        mobileImage={{
          maxWidth: 800,
          maxHeight: 600,
          sizes: '100vw',
        }}
      />
      <GradientBlock />
      <Container size="xl">
        <div className={classNames.filters}>
          <SchoolFilters
            heading={heading}
            headingFontSize={headingFontSize}
            btnCaption={btnCaption}
          />
        </div>
      </Container>
    </div>
  )
}
