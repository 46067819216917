import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import { CSSTransition } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'

import CustomTypography from './widgets/CustomTypography'
import classNames from './styles.module.scss'
import ImageType from '../types/ImageType'
import BynderPicture from 'common/widgets/BynderPicture/BynderPicture'


Person.propTypes = {
  image: ImageType,
  name: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

Person.defaultProps = {
  image: {},
  name: undefined,
  title: undefined,
}

export default function Person({ image, name, title, onClick }) {
  const person = useRef()
  const [isSelected, setIsSelected] = useState(false)
  const [personStyles, setPersonStyles] = useState(undefined)

  const handleClick = useCallback(() => {
    setIsSelected(!isSelected)
    document.documentElement.style.overflow = isSelected ? 'auto' : 'hidden'
    onClick()
  }, [isSelected, setIsSelected, onClick])

  const handleLoad = useCallback(() => {
    const bounding = person.current.getBoundingClientRect()
    setPersonStyles({
      width: bounding.width,
      height: bounding.height,
      left: bounding.left,
      top: bounding.top,
    })
  }, [setPersonStyles])

  useEffect(() => {
    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [])

  return (
    <Col span={24} sm={12} lg={6}>
      <div className={classNames.outer}>
        <CSSTransition
          in={isSelected}
          timeout={450}
          classNames={{
            enter: classNames.personEnter,
            enterActive: classNames.personEnterActive,
            enterDone: classNames.personEnterDone,
            exit: classNames.personExit,
            exitActive: classNames.personExitActive,
            exitDone: classNames.personExitDone,
          }}
        >
          <div
            ref={person}
            className={cx(classNames.person, isSelected && classNames.selected)}
            style={personStyles}
            onClick={handleClick}
          >
            <div
              className={classNames.personImage}
            >
              <BynderPicture
                image={image}
                onLoad={handleLoad}
                desktopImage={{
                  maxWidth: 450,
                  maxHeight: 450,
                  sizes: '450px',
                }}
                mobileImage={{
                  maxWidth: 450,
                  maxHeight: 450,
                  sizes: '450px',
                }}
              />
            </div>
            <div className={classNames.personDetails}>
              <CustomTypography variant="name">{name}</CustomTypography>
              <CustomTypography variant="title" className={classNames.title}>{title}</CustomTypography>
            </div>
            <div className={classNames.iconContainer}>
              <FontAwesomeIcon icon="arrow-left" className={classNames.icon} />
            </div>
          </div>
        </CSSTransition>
      </div>
    </Col>
  )
}
