import { get } from 'lodash'
import bynderDATLoader from './bynderDATLoader'


export const getOgImage = (imageData) => {
  const bynderId = get(imageData, 'value.image.bynder', '')
  const bynderName = get(imageData, 'value.image.data.name', '')

  // use pre-defined width and height for og:image
  return bynderDATLoader({
    src: `https://img.boalingua.ch/transform/${bynderId}/${bynderName}`,
    maxWidth: 800,
    width: 800,
    maxHeight: 533,
  })
}
