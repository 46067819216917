import { useState, useCallback } from 'react'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Loading } from 'common/widgets'
import PlayButton from './PlayButton'
import classNames from './styles.module.scss'


VideoContainer.propTypes = {
  video: PropTypes.string,
  videoImage: PropTypes.string,
  type: PropTypes.string,
  aspectRatio: PropTypes.oneOf(['v', 'h']).isRequired,
  withHeading: PropTypes.bool,
}

VideoContainer.defaultProps = {
  video: undefined,
  videoImage: undefined,
  type: 'video/mp4',
  withHeading: false,
}

const Video = dynamic(() => import('./Video'), { ssr: false,
  loading: () => <Loading isLoading fontSize={48} /> })

export default function VideoContainer({ video: src, type, videoImage, aspectRatio, withHeading }) {
  const [isPlayerEnabled, setIsPlayerEnabled] = useState(!videoImage)
  const [player, setPlayer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handleClick = useCallback(() => {
    setIsPlayerEnabled(isPlayerEnabled => {
      if(!isPlayerEnabled) {
        return true
      }
      if(player) {
        if(player.paused() && isPlaying === false) {
          player.play()
        }
      }
      return isPlayerEnabled
    })
  }, [isPlaying, setIsPlayerEnabled, player])

  return (
    <div
      className={cx(classNames.videoContainer, aspectRatio === 'v' ? classNames.vertical : classNames.horizontal, withHeading && classNames.withHeading)}
      onClick={handleClick}
    >
      {isPlayerEnabled
        ? (
          <Video src={videoImage ? src : src + '#t=0.1'} type={type} videoImage={videoImage} setIsPlaying={setIsPlaying} setPlayer={setPlayer} />)
        : (
          <Image
            src={videoImage}
            layout="fill"
            sizes="(max-width: 767px) 100vw,
                     33vw"
            objectFit="cover"
            alt="video image poster"
          />
        )}
      {!isPlaying && (
        <div className={classNames.playButtonContainer}>
          <PlayButton />
        </div>)}
    </div>
  )
}
