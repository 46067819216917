import { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { BynderPicture, Typography } from 'common/widgets'
import CustomTypography from '../CustomTypography'
import classNames from './styles.module.scss'
import ImageType from '../../../types/ImageType'
import bynderDATLoader from 'common/utils/bynderDATLoader'


DetailModal.propTypes = {
  person: PropTypes.shape({
    image: ImageType,
    name: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
  }),
}

DetailModal.defaultProps = {
  person: {},
}

export default function DetailModal({ person }) {
  const bgImage = useMemo(()=>{
    if(!person?.image) {
      return 'none'
    }

    const source = bynderDATLoader({
      src: `https://img.boalingua.ch/transform/${person.image.bynder}/bg`,
      maxWidth: 600,
      width: 600,
      maxHeight: 800,
    })
    return `url(${source})`
  }, [person.image])
  return (
    <div className={cx(classNames.detail, !isEmpty(person) && classNames.active)}>
      <div className={classNames.detailInner}>
        <div className={classNames.detailLeft} style={{ backgroundImage: bgImage }}>
          <div className={classNames.detailPhoto}>
            <div>
              {person.image && (
                <BynderPicture
                  priority
                  image={person.image}
                  desktopImage={{
                    maxWidth: 650,
                    maxHeight: 650,
                    sizes: '(max-width: 1024px) 80vw, 500px',
                  }}
                  mobileImage={{
                    maxWidth: 500,
                    maxHeight: 500,
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classNames.detailRight}>
          <div className={classNames.detailHeader}>
            <CustomTypography variant="name">{person.name}</CustomTypography>
            <CustomTypography variant="title">{person.title}</CustomTypography>
          </div>
          <Typography
            variant="content"
            color="black"
            tag="div"
            dangerouslySetInnerHTML={{ __html: person.text || '' }}
          />
        </div>
      </div>
    </div>
  )
}
