import PropTypes from 'prop-types'
import { Col } from 'antd'

import { BynderPicture, CTALink, SquareBlock, Typography } from 'common/widgets'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


ImageItem.propTypes = {
  image: ImageType,
  subheading: PropTypes.string,
  heading: PropTypes.string,
  caption: PropTypes.string,
  tag: PropTypes.string,
  cta_link: PropTypes.string,
  linkType: PropTypes.string,
}

ImageItem.defaultProps = {
  image: undefined,
  subheading: undefined,
  heading: undefined,
  caption: undefined,
  tag: undefined,
  cta_link: undefined,
  linkType: undefined,
}

export default function ImageItem({ image, heading, subheading, caption, tag, cta_link: url, linkType }) {
  return (
    <Col span={24} md={12} lg={8}>
      <SquareBlock
        containerClassName={classNames.container}
        innerClassName={classNames.inner}
        image={
          <BynderPicture
            priority
            image={image}
            desktopImage={{
              maxWidth: 550,
              maxHeight: 550,
              sizes: '(max-width: 765px) 100vw, (max-width: 1024px) 50vw, (max-width: 1920px) 33vw, 550px',
            }}
            mobileImage={{
              maxWidth: 550,
              maxHeight: 550,
              sizes: '100vw',
            }}
          />
        }
      >
        <Typography variant="content" color="white" className={classNames.name}><b>{tag}</b></Typography>
        <Typography variant="h3" color="white" className={classNames.name}>{heading}</Typography>
        <Typography color="white">{subheading}</Typography>
        <CTALink
          cta={caption}
          ctaURL={url}
          isUnderline
          type={linkType}
        />
      </SquareBlock>
    </Col>
  )
}
