import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { BlockWrapper, Typography, GradientBlock, BynderPicture } from 'common/widgets'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


HeroWithText.propTypes = {
  data: PropTypes.shape({
    quote_blocks: PropTypes.arrayOf(PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
      image: ImageType,
    })),
  }).isRequired,
}

export default function HeroWithText({ data }) {
  const blocks = useMemo(() => data.quote_blocks.map(({ heading, text, image }, index) => {
    return (
      <BlockWrapper key={index}>
        {heading && (
          <div className={classNames.hero}>
            <BynderPicture
              image={image}
              priority
              mobileImage={{
                maxWidth: 720,
                maxHeight: 300,
                sizes: '100vw',
              }}
              desktopImage={{
                maxWidth: 1440,
                maxHeight: 400,
                sizes: '(max-width: 1440px) 100vw, 1440px',
              }}
            />
            <GradientBlock />
            <div className={classNames.headingText}>
              <Typography variant="aboutHero" color="white" transform="uppercase">
                {heading}
              </Typography>
            </div>
          </div>
        )}
        {text && (
          <div className={classNames.text}>
            <Typography variant="content" color="black">
              {text}
            </Typography>
          </div>
        )}
      </BlockWrapper>
    )
  }), [data.quote_blocks])
  return blocks
}
