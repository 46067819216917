import get from 'lodash/get'
import PropTypes from 'prop-types'

import { Typography, BynderPicture } from 'common/widgets'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


ImageSlide.propTypes = {
  image: ImageType.isRequired,
  priority: PropTypes.bool.isRequired,
}


export default function ImageSlide({ image, priority }) {
  return (
    <div className={classNames.slideWrapper}>
      <div className={classNames.imageWrapper}>
        <BynderPicture
          image={image}
          priority={priority}
          mobileImage={{
            maxWidth: 600,
            maxHeight: 720,
            aspectRatio: 5 / 6,
            sizes: '100vw',
          }}
          desktopImage={{
            maxWidth: 512,
            maxHeight: 768,
            aspectRatio: 2 / 3,
            sizes: '(max-width: 768px) 80vw, 35vw',
          }}
        />
      </div>
      <Typography variant="small" className={classNames.caption}>{get(image, 'data.caption')}</Typography>
    </div>
  )
}
