import PropTypes from 'prop-types'
import moment from 'moment'
import cx from 'classnames'

import { CTALink, Typography, BynderPicture } from 'common/widgets'
import classNames from './styles.module.scss'


MasonryItem.propTypes = {
  image: PropTypes.object,
  tag: PropTypes.string,
  date: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.string,
  cta_link: PropTypes.string,
  cta_link_type: PropTypes.string,
  headingTag: PropTypes.string,
}

MasonryItem.defaultProps = {
  image: {},
  tag: undefined,
  date: undefined,
  heading: undefined,
  text: undefined,
  cta: undefined,
  cta_link: undefined,
  cta_link_type: undefined,
  headingTag: undefined,
}

export default function MasonryItem({ image, tag, date, heading, text, cta, cta_link: ctaLink, cta_link_type: ctaLinkType, headingTag }) {
  return (
    <div className={classNames.item}>
      <div className={classNames.imageContainer}>
        {image?.bynder && (
          <BynderPicture
            image={image}
            mobileImage={{
              maxWidth: 600,
              maxHeight: 600,
              sizes: '100vw',
            }}
            desktopImage={{
              maxWidth: 512,
              maxHeight: 512,
              sizes: '(max-width: 1024px) 50vw, 33vw',
            }}
          />
        )}
      </div>
      <div className={classNames.info}>
        <Typography
          variant="content"
          className={cx(classNames.infoText, classNames.tagBlock)}
        >
          {tag && (
            <span className={cx(classNames.name, classNames.infoText)}>
              {tag}
            </span>
          )}
          {date && moment(date, 'YYYY-MM-DD').format('MMMM[,] YYYY')}
        </Typography>
        <Typography
          variant="h3"
          tag={headingTag}
          className={cx(classNames.infoText, classNames.heading)}
        >
          {heading}
        </Typography>
        <Typography
          variant="content"
          className={cx(classNames.text, classNames.infoText)}
        >
          {text}
        </Typography>
        <CTALink
          cta={cta}
          ctaURL={ctaLink}
          type={ctaLinkType}
          isUnderline
          className={cx(classNames.cta, classNames.infoText)}
        >
          {cta}
        </CTALink>
      </div>
    </div>
  )
}
