import { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Container, ImageBlock, Typography, BlockWrapper } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'
import { ImageType } from '../types'


ImageGridPortrait.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: ImageType,
      heading: PropTypes.string,
      text: PropTypes.string,
      rating: PropTypes.number,
      cta_link: PropTypes.string,
    })),
    cta_link_caption: PropTypes.string,
  }),
}

ImageGridPortrait.defaultProps = {
  data: {
    items: [],
  },
}

export default function ImageGridPortrait({ data }) {
  const { gettext } = useTranslations()
  const options = useMemo(() => data.items.map(({ rating, ...option }, idx) => (
    <div
      key={idx + option.heading}
      className={classNames.column}
    >
      <ImageBlock
        {...option}
        cta={data.cta_link_caption}
        className={classNames.image}
      >
        {rating && (
          <div className={classNames.scoreContainer}>
            <Typography
              variant="h2"
              tag="div"
              color="white"
              className={cx(classNames.noMargin, classNames.scoreTitle)}
            >
              {rating ? rating.toFixed(1) : 0}
            </Typography>
            <Typography
              variant="content"
              color="white"
              className={classNames.noMargin}
            >
              {gettext('von')} 5
            </Typography>
          </div>
        )}
      </ImageBlock>
    </div>
  )), [data.items])

  return (
    <BlockWrapper>
      <Container size="xl">
        <div className={classNames.imageGridPortrait}>{options}</div>
      </Container>
    </BlockWrapper>
  )
}
